import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { QrReader } from "react-qr-reader";
import isMobileDevice from "helpers/isMobile";
import { apiValidador } from "services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HashDocumento from "assets/hash-documento.png";
import QRCodeDocumento from "assets/qrcode-documento.png";
import {
  formatarCPF,
  formatarStringData,
  formatarTelefone,
} from "helpers/Formatters";
import {
  TAMANHO_HASH_DOCUMENTO,
  DOCUMENTO_ORIGINAL,
  DOCUMENTO_EVIDENCIA,
  LARGURA_MEDIA,
  LARGURA_PEQUENA,
  TAMANHO_IDENTIFICADOR_DOCUMENTO,
} from "./consts";
import PageLoader from "components/PageLoader";
import BemSignArte from "assets/bemsign-arte.svg";
import LogoBemSign from "assets/logo-bemsign-v2.svg";
import {
  AiOutlineLeft,
  AiOutlineDownload,
  AiOutlineFileProtect,
  AiOutlineFileZip,
} from "react-icons/ai";

export default function Validador(props) {
  const [dados, setDados] = useState(null);
  const [QRCodeVisivel, setQRCodeVisivel] = useState(false);
  const [linhaDigitavelVisivel, setLinhaDigitavelVisivel] = useState(false);
  const [linhaDigitavel, setLinhaDigitavel] = useState("");
  const [identificadorDocumento, setIdentificadorDocumento] = useState("");
  const [carregando, setCarregando] = useState(false);
  const [larguraWindow, setLarguraWindow] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const history = useHistory();
  const [exibirDownloadLoteDocumentos, setExibirDownloadLoteDocumentos] =
    useState(false);

  const validarQRCode = async (identificador) => {
    try {
      setCarregando(true);

      const tokenStr = await (
        await apiValidador.get(
          `Autenticacao/AutenticarIdentificador/${identificador}`
        )
      ).data.retorno.tokenAutenticacao;
      setAuthToken(tokenStr);

      const response = await apiValidador.get(
        `BemSign/ValidarIdentificadorDocumento/${identificador}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      setCarregando(false);

      if (response.data.retorno) {
        formataDados(response.data.retorno);
      } else {
        erroDocumento();
      }
    } catch (error) {
      setCarregando(false);
      erroDocumento();
    }
  };

  const formataDados = (dados) => {
    var listaDados = [];

    dados.forEach((dado) => {
      dado.nomeDocumento = dado.nomeDocumento.replace(
        `-${dado.nomeSignatario}`,
        ""
      );
      dado.celular =
        dado.ddd && dado.celular
          ? formatarTelefone(`${dado.ddd}${dado.celular}`)
          : "";

      listaDados.push(dado);
    });
    setDados(listaDados);
  };

  const erroDocumento = () => {
    setDados(null);
    setQRCodeVisivel(false);
    toast.error("O documento solicitado não foi encontrado");
  };

  const onValidarQRCode = () => {
    window.scrollTo(0, 0);
    setDados(null);
    setIdentificadorDocumento("");
    setLinhaDigitavel("");
    setQRCodeVisivel(true);
    setLinhaDigitavelVisivel(false);
  };

  const onLinhaDigitavel = () => {
    setLinhaDigitavelVisivel(true);
    setQRCodeVisivel(false);
  };

  const resultadoQRCode = (result, error) => {
    if (!!result) {
      const texto = result.text;

      const urlRegex = /^https?:\/\/[^\s/$.?#].[^\s]*$/;

      if (urlRegex.test(texto)) {
        const url = new URL(texto);

        const caminho = url.pathname;

        const partesDoCaminho = caminho.split("/");

        const codigo = partesDoCaminho[partesDoCaminho.length - 1];

        setIdentificadorDocumento(codigo);
      } else {
        setIdentificadorDocumento(texto);
      }
    }
  };

  const BotaoValidarDocumento = (props) => (
    <button className="button-bem button-resize" onClick={onValidarQRCode}>
      {props.texto ? props.texto : "Validar um documento"}
    </button>
  );

  const baixarDocumento = async (documento) => {
    const tipoDoc = documento === DOCUMENTO_ORIGINAL ? "original" : "evidencia";
    const metodo =
      documento === DOCUMENTO_ORIGINAL
        ? "ObterDocumentoOriginalPorIdentificador"
        : "ObterDocumentoEvidenciaPorIdentificador";

    try {
      setCarregando(true);

      const response = await apiValidador.get(
        `/BemSign/${metodo}/${identificadorDocumento}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      setCarregando(false);

      const nomeArquivo = `documento-${tipoDoc}.pdf`;
      const linkPDF = `data:application/pdf;base64,${response.data.retorno}`;
      const a = document.createElement("a");
      a.href = linkPDF;
      a.download = nomeArquivo;
      a.click();
    } catch (err) {
      setCarregando(false);
      toast.error("Erro ao baixar documento");
    }
  };

  const baixarLoteDocumentos = async () => {
    try {
      setCarregando(true);

      const response = await apiValidador.get(
        `/Documento/ObterArquivosDeEvidencia/${identificadorDocumento}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          "Content-Type": "application/octed-stream",
          responseType: "blob",
        }
      );

      setCarregando(false);

      const identificadorArquivoNome = identificadorDocumento.substring(0, 10);

      const nomeArquivo = `lote-documentos-evidencia-${identificadorArquivoNome}.zip`;

      const blob = new Blob([response.data], { type: "application/zip" });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = nomeArquivo;
      a.click();
    } catch (err) {
      setCarregando(false);
      toast.error(
        "Erro ao baixar o lote de evidências relacionadas ao documento"
      );
    }
  };

  const buscarPorCodigo = () => {
    setIdentificadorDocumento(linhaDigitavel);
  };

  const Loader = () => (
    <section
      className={`loader-animation ${carregando ? "enabled" : "hidden"}`}
    >
      <PageLoader isOverlay="true" width={125} height={250} />
    </section>
  );

  const FotoCliente = ({ fotoBase64 }) => {
    return (
      fotoBase64 && (
        <img
          src={`data:image/png;base64,${fotoBase64}`}
          alt="foto do cliente"
          className="foto-cliente"
        />
      )
    );
  };

  useEffect(() => {
    const handleResize = () => {
      if (
        window.innerWidth > LARGURA_MEDIA ||
        window.innerWidth <= LARGURA_PEQUENA
      ) {
        setLarguraWindow(true);
      } else {
        setLarguraWindow(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const evd = searchParams?.get("evd");
    setExibirDownloadLoteDocumentos(!(evd && evd?.length > 0));
  }, [window.location.search]);

  useEffect(() => {
    if (
      identificadorDocumento &&
      identificadorDocumento.length >= TAMANHO_IDENTIFICADOR_DOCUMENTO
    )
      validarQRCode(identificadorDocumento);
  }, [identificadorDocumento]);

  useEffect(() => {
    const identificador = props.match.params.identificador;

    if (identificador) {
      setIdentificadorDocumento(identificador);
    }
  }, [props.match.params]);

  return (
    <div className="validador-container">
      <div className="bemsign">
        <div className="info">
          <img src={LogoBemSign} id="logo-bemsign" alt="logo da bemsign" />
          <p>Seu portal de assinatura eletrônica</p>
          <p>Dando segurança e agilidade na formalização dos seus documentos</p>
        </div>

        <img
          src={BemSignArte}
          id="bemsign-arte"
          alt="imagem do portal de assinatura"
        />
      </div>

      <div className="conteudo">
        <Loader />
        <header>
          {dados && dados.length > 0 && (
            <AiOutlineLeft
              size={20}
              onClick={() => (window.location.href = "/verificador-assinatura")}
              cursor="pointer"
            />
          )}
          <h1 className="titulo">
            Validador <b>BemSign</b>
          </h1>
        </header>

        {!QRCodeVisivel && !linhaDigitavelVisivel && !dados && (
          <div className="dica-qrcode">
            <div className="instrucao">
              <p>
                Verifique se um documento está corretamente assinado através do
                QRCode ou digitando o HASH do documento.
              </p>
              <p>
                O QRCode e o HASH do documento se encontram no protocolo de
                assinatura.
              </p>
            </div>
            <div className="exemplo">
              <img src={HashDocumento} alt="Hash do documento" width={"50%"} />
              <img
                src={QRCodeDocumento}
                alt="QRCode do documento"
                width={"50%"}
              />
            </div>
            <BotaoValidarDocumento />
          </div>
        )}

        {dados && dados.length > 0 && (
          <>
            <div className="container-verificacao-assinatura">
              {dados.map((dado, index) => (
                <div className="dados-assinatura" key={index}>
                  <div className="container-dados">
                    <label>Status</label>
                    <p className="validado">Validado</p>

                    <label>Nome</label>
                    <p>{dado.nomeSignatario}</p>

                    <label>CPF</label>
                    <p>{formatarCPF(dado.cpfSignatario)}</p>

                    <label>Nome do documento</label>
                    <p>{dado.nomeDocumento}</p>

                    <label>Criado em</label>
                    <p>{formatarStringData(dado.dataCriacao)}</p>
                  </div>
                  <div className="container-dados">
                    <label>Telefone autenticado</label>
                    <p>
                      {dado.celular}/{dado.dispositivoDescricao}/
                      {dado.navegador}
                    </p>

                    <label>Localização</label>
                    <p>{`${dado.latitude} ${dado.longitude}`}</p>

                    <label>Assinado em</label>
                    <p>{formatarStringData(dado.dataAssinatura)}</p>

                    <label>IP</label>
                    <p>{dado.ip}</p>
                  </div>
                  <div className="container-dados foto">
                    <FotoCliente fotoBase64={dado.fotoBase64} />
                    <button
                      className="button-bem button-fundo-escuro"
                      onClick={() => baixarDocumento(DOCUMENTO_ORIGINAL)}
                    >
                      <AiOutlineDownload size={20} />
                      Baixar documento original
                    </button>
                    <button
                      className="button-bem button-fundo-vazado"
                      onClick={() => baixarDocumento(DOCUMENTO_EVIDENCIA)}
                    >
                      <AiOutlineFileProtect size={20} />
                      Baixar documento assinado
                    </button>
                    {exibirDownloadLoteDocumentos && (
                      <button
                        className="button-bem button-fundo-escuro"
                        onClick={() => baixarLoteDocumentos()}
                      >
                        <AiOutlineFileZip size={20} />
                        Baixar lote de evidências
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {QRCodeVisivel && !dados && (
          <>
            <h2>Aponte a câmera para o QRCode</h2>
            <QrReader
              onResult={resultadoQRCode}
              className="video-qrcode"
              constraints={{
                facingMode: isMobileDevice()
                  ? { exact: "environment" }
                  : "user",
              }}
            />
            <button
              className="button-bem button-resize"
              onClick={onLinhaDigitavel}
            >
              Digitar o código
            </button>
          </>
        )}

        {linhaDigitavelVisivel && !dados && (
          <>
            <h2>Digite o Hash ou Identificador do documento no campo abaixo</h2>
            <input
              value={linhaDigitavel}
              onChange={(e) => setLinhaDigitavel(e.target.value)}
              className="identificador-documento"
              placeholder="digite o código..."
              maxLength={TAMANHO_HASH_DOCUMENTO}
            />
            <button
              className="button-bem button-resize"
              onClick={buscarPorCodigo}
              disabled={linhaDigitavel.length < TAMANHO_IDENTIFICADOR_DOCUMENTO}
            >
              Buscar
            </button>
            <button
              className="button-bem button-resize secundario"
              onClick={onValidarQRCode}
            >
              Ler um QRCode
            </button>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}
