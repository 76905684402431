import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from 'services/api';

import Cell from 'assets/cell.png';

import { useApp } from 'context/App';

import audioToken from 'assets/audios/token-3.mp3';

import { useAudio } from 'react-use';
import { VOLUME } from 'services/consts';
import './styles.css';

export default function Token() {
  const [codigo, setCodigo] = useState('');
  const [idToken, setIdToken] = useState('');
  const [executando, setExecutando] = useState(false);
  const history = useHistory();
  const { tokenSignatario, setTokenSMS, celular, proximaPagina, ajudaPorVoz } =
    useApp();
  const [audio, state, controls, ref] = useAudio({
    src: audioToken,
    autoPlay: false,
  });

  useEffect(() => {
    const fecthData = async () => {
      try {
        setExecutando(true);

        const response = await api.post(
          `Autenticacao/EnviarToken/${tokenSignatario}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                'autorizacao',
              )}`,
              'Content-Type': 'application/json',
            },
          },
        );

        setExecutando(false);

        if (ajudaPorVoz) {
          controls.volume(VOLUME);
          controls.play();
        }

        setIdToken(response.data.retorno);
      } catch (error) {
        setExecutando(false);
        toast.error('Ocorreu um erro ao enviar o código');
      }
    };
    fecthData();
  }, []);

  async function reenviarToken() {
    try {
      setExecutando(true);

      const response = await api.post(
        `Autenticacao/ReenviarToken/${idToken}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'autorizacao',
            )}`,
            'Content-Type': 'application/json',
          },
        },
      );

      setExecutando(false);

      if (response.data.retorno) {
        toast.info('O código foi reenviado');
      } else {
        toast.error('Ocorreu um erro ao reenviar o código');
      }
    } catch (error) {
      setExecutando(false);
      toast.error('Ocorreu um erro ao reenviar o código');
    }
  }

  async function validarToken() {
    try {
      setExecutando(true);

      const response = await api.post(
        `Autenticacao/ValidarToken/${idToken}/${codigo}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'autorizacao',
            )}`,
            'Content-Type': 'application/json',
          },
        },
      );

      setExecutando(false);

      return response.data.retorno;
    } catch (error) {
      setExecutando(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.erros.length
      ) {
        error.response.data.erros.map((erro) => toast.error(erro.mensagem));
      } else {
        toast.error('O código informado não é valido');
      }
      return false;
    }
  }

  async function proximo(e) {
    e.preventDefault();

    const tokenValido = await validarToken();

    if (tokenValido) {
      setTokenSMS(codigo);
      navegarProximaPagina();
    }
  }

  function navegarProximaPagina() {
    history.push(proximaPagina());
  }

  return (
    <div className="container token-container">
      {audio}
      <section className="form">
        <h1>Verificação de telefone</h1>
        <h2>
          Uma mensagem de texto foi enviada para o seu telefone com final
          (**)*****{celular.substring(celular.length - 4)}.
        </h2>

        <img src={Cell} alt="celular" />

        <form>
          <input
            placeholder="Digite o código de 4 dígitos"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
          />

          <p className="codigo-reenvio">
            Não recebeu o código? <a onClick={reenviarToken}>Clique Aqui!</a>
          </p>

          <button className="button-bem" onClick={proximo} disabled={executando}>
            Próxima
          </button>
        </form>
      </section>
      <ToastContainer />
    </div>
  );
}
