import React, { useEffect, useRef, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { cpfMask } from 'helpers/masks';
import { BIOMETRIA_ETAPA, QUANTIDADE_REQUISICOES, STATUS_PROCESSO } from './consts';
import './styles.css';
import Webcam from 'react-webcam';
import api, { apiPortalIdentificador } from 'services/api';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageLoader from 'components/PageLoader';
import { isValidTokenScore } from 'helpers/authScore';
import ScoreExibicao from 'pages/ScoreExibicao';
import isMobileDevice from 'helpers/isMobile';

export default function BiometriaScore({ location }) {
    const state = location.state;
    const [etapaBiometria, setEtapaBiometria] = useState(BIOMETRIA_ETAPA.INFORMACOES);
    const webcamRef = useRef(null);
    const [modoCamera, setModoCamera] = useState(isMobileDevice() ? { exact: 'environment' } : 'user');
    const [imgSrc, setImgSrc] = useState(null);
    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [scoreInfo, setScoreInfo] = useState(null);
    const [activeDeviceId, setActiveDeviceId] = useState();
    const [loaded, setLoaded] = useState(false);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const history = useHistory();

    const obterGeolocalizacao = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position.coords.latitude && position.coords.longitude) {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                }
            })
        }
    }

    useEffect(() => {
        const valido = isValidTokenScore();

        if (!valido) return history.push('/login');

        obterGeolocalizacao();

        if (state.cpf && state.nome) {
            setCpf(state.cpf);
            setNome(state.nome);
            confirmar();
        }
    }, []);

    const obterDadosScoreBiometricoTela = async (idScore, repeticao = 0) => {
        setIsLoading(true);

        try {
            const response = await apiPortalIdentificador.get(`ScoreBiometrico/ObterDadosScoreBiometricoTela/${idScore}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('autorizacao-score')}`,
                    'Content-Type': 'application/json'
                }
            });

            const status = response.data.retorno.statusProcesso;

            switch (status) {
                case STATUS_PROCESSO.ERRO:
                    toast.error('Tente novamente');
                    setImgSrc(null);
                    setEtapaBiometria(BIOMETRIA_ETAPA.INFORMACOES);
                    setIsLoading(false);
                    break;
                case STATUS_PROCESSO.EXIBE:
                    setScoreInfo(response.data.retorno);
                    setIsLoading(false);
                    break;
                default:
                    if (repeticao <= QUANTIDADE_REQUISICOES) {
                        repeticao = repeticao + 1;
                        setTimeout(() => obterDadosScoreBiometricoTela(idScore, repeticao), 1000);
                    } else {
                        history.push('/score');
                    }
                    break;
            }
        } catch (error) {
            toast.error("Erro ao obter dados do score biométrico");
            setIsLoading(false);
        }
    }

    const trocaCamera = () => {
        setModoCamera(modoCamera === 'user' ? { exact: 'environment' } : 'user');
    }

    const confirmar = (event) => {
        if (event) {
            event.preventDefault();
        }

        if (etapaBiometria === BIOMETRIA_ETAPA.INFORMACOES) {
            setEtapaBiometria(BIOMETRIA_ETAPA.BIOMETRIA);
        }
    }

    const changeNome = (value) => {
        setNome(value);
    }

    const changeCpf = (value) => {
        setCpf(value);
    }

    const capturaFoto = () => {
        const imgSrc = webcamRef.current.getScreenshot();
        setImgSrc(imgSrc);
    }

    const enviar = async () => {
        const data = {
            nome,
            cpf: cpf.replaceAll('.', '').replace('-', ''),
            biometriaBase64: imgSrc.split(',')[1],
            latitude,
            longitude
        }

        try {
            setIsLoading(true);

            const response = await apiPortalIdentificador.post(`ScoreBiometrico/GerarScore`, data, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('autorizacao-score')}`,
                    'Content-Type': 'application/json',
                },
            });

            setIsLoading(false);

            obterDadosScoreBiometricoTela(response.data.retorno);
        } catch (error) {
            setIsLoading(false);

            error.response &&
                error.response.data &&
                error.response.data.erros.length
                ? toast.error(error.response.data.erros[0].mensagem, { autoClose: false }) :
                toast.error('Ocorreu um erro ao gerar score');
        }
    }

    const tirarNovamente = () => {
        setImgSrc(null);
    }

    const getVideoConstraints = () => {
        let constraint = { facingMode: modoCamera };
        if (activeDeviceId) {
            constraint = { ...constraint, deviceId: activeDeviceId }
        }
        return constraint;
    };

    useEffect(() => {
        (async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const devicesBack = devices.filter(x => x.label.indexOf('back') > 0)
            if (devicesBack && devicesBack.length) {
                setActiveDeviceId(devicesBack[devicesBack.length - 1].deviceId);
            }
            setLoaded(true);
        })();
    }, []);

    return <div className='container biometria-score-container'>
        {etapaBiometria === BIOMETRIA_ETAPA.INFORMACOES && !scoreInfo && <div className='informacoes'>
            <h1>Digite os dados do cliente</h1>
            <form onSubmit={confirmar}>
                <fieldset>
                    <legend>Nome</legend>
                    <input onChange={(e) => changeNome(e.target.value)} />
                </fieldset>
                <fieldset>
                    <legend>CPF</legend>
                    <MaskedInput
                        guide={false}
                        mask={cpfMask}
                        onChange={(e) => changeCpf(e.target.value)}
                        autoComplete="off"
                        type="tel"
                    />
                </fieldset>
                <button className="button-bem" disabled={!nome || !cpf}>
                    Confirmar
                </button>
            </form>
        </div>}

        {etapaBiometria === BIOMETRIA_ETAPA.BIOMETRIA && !scoreInfo && <div className='camera'>
            {!imgSrc && <>
                <h1>Enquadre o rosto para tirar a foto</h1>
                {!imgSrc && loaded && <div>
                    <Webcam
                        className="camera"
                        audio={false}
                        ref={webcamRef}
                        imageSmoothing
                        screenshotFormat="image/jpeg"
                        mirrored={modoCamera === 'user'}
                        controls={false}
                        videoConstraints={getVideoConstraints()}
                    />
                </div>}
                <button className="button-bem" onClick={capturaFoto}>Tirar Foto</button>
                {isMobileDevice() && <button className="button-bem finalizar" onClick={trocaCamera}>Trocar Câmera</button>}
            </>}
            {imgSrc && <>
                <h1>Verifique a qualidade da foto</h1>
                <img src={imgSrc} alt="foto capturada" />
                <button className="button-bem" onClick={enviar}>Ficou boa! Enviar</button>
                <button className="button-bem finalizar" onClick={tirarNovamente}>Tirar Novamente</button>
            </>}
        </div>
        }
        {scoreInfo && <ScoreExibicao data={scoreInfo} />}
        <ToastContainer />
        <section
            className={`loader-animation ${isLoading ? 'enabled' : 'hidden'}`}
        >
            <PageLoader isOverlay="true" width={125} height={250} />
        </section>
    </div >
}