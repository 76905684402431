import api, { apiPortalIdentificador } from 'services/api';
import useDownload from './useDownload';
import { useState } from 'react';

const useDownloadBiometria = () => {
    const { download } = useDownload();
    const [loading, setLoading] = useState(false);

    const baixar = async (id) => {
        setLoading(true);

        try {
            const response = await apiPortalIdentificador
                .get(`ScoreBiometrico/GerarRelatorioCadastroBiometrico/${id}`, {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('autorizacao-score')}`,
                    },
                    responseType: 'blob'
                });

            download(response.data, `biometria-${id}`);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    return { baixar, loading };
}

export default useDownloadBiometria;