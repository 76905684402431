import axios from "axios";
import {
  API_BEMSIGN_URL,
  API_BEMSIGN_AUTENTICACAO_URL,
  API_VALIDADOR_URL,
  API_PORTAL_IDENTIFICADOR_URL,
  API_MESA_ANALISE_VIDEO,
} from "./consts";

const api = axios.create({
  baseURL: API_BEMSIGN_URL,
});

export const apiAuth = axios.create({
  baseURL: API_BEMSIGN_AUTENTICACAO_URL,
});

export const apiValidador = axios.create({
  baseURL: API_VALIDADOR_URL,
});

export const apiPortalIdentificador = axios.create({
  baseURL: API_PORTAL_IDENTIFICADOR_URL,
});

export const apiMesaAnaliseVideo = axios.create({
  baseURL: API_MESA_ANALISE_VIDEO,
});

export default api;
