import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { base64toBlob } from "helpers/base64Blob";
import { FaSpinner } from "react-icons/fa";
import {
  formatarCPF,
  formatarStringDataHoraSegundos,
} from "helpers/Formatters";
import api, { apiMesaAnaliseVideo } from "services/api";
import "./styles.css";

export default function VideoUsuario(props) {
  const history = useHistory();
  const [videoBlob, setVideoBlob] = useState(null);
  const [loteNome, setLoteNome] = useState("");
  const [descricaoInstrucao, setDescricaoInstrucao] = useState("");
  const [carregando, setCarregando] = useState(true);
  const [scoreBiometrico, setScoreBiometrico] = useState(null);

  useEffect(() => {
    const executaVideo = async () => {
      if (props.match.params.token) {
        setCarregando(true);

        const biometriaVideo = await obterBiometriaVideoPorSignatario(
          props.match.params.token
        );

        const dadosScoreBiometrico = await obterDetalhamentoScoreBiometrico(
          props.match.params.token
        );
        setScoreBiometrico(dadosScoreBiometrico);

        setCarregando(false);

        if (biometriaVideo) {
          const blob = base64toBlob([biometriaVideo.videoBase64]);

          setVideoBlob(URL.createObjectURL(blob));

          setLoteNome(biometriaVideo.lote);
          setDescricaoInstrucao(biometriaVideo.descricaoInstrucao);
        }
      } else {
        return aviso("Não foi possível carregar informações");
      }
    };

    executaVideo();
  }, []);

  const aviso = (mensagem) => {
    return history.push("/aviso", {
      textoH1: mensagem,
      showImage: true,
    });
  };

  const obterBiometriaVideoPorSignatario = async (tokenSignatario) => {
    try {
      const response = await apiMesaAnaliseVideo.get(
        `BemSign/ObterBiometriaVideoPorSignatario/${tokenSignatario}`
      );

      return response.data.retorno;
    } catch (error) {
      return null;
    }
  };

  const obterDetalhamentoScoreBiometrico = async (tokenSignatario) => {
    try {
      const response = await apiMesaAnaliseVideo.get(
        `ScoreBiometrico/ObterProcessoDetalhamento/${tokenSignatario}`
      );

      if (response.data.retorno && response.data.retorno.logs) {
        const logs = response.data.retorno.logs;
        response.data.retorno.logs = logs.map((item) => {
          const logDestaque = logs.filter((x) => x.idProcessoLogTipo === 2)[0];
          return {
            ...item,
            darDestaque: logDestaque && logDestaque.mensagem === item.mensagem,
          };
        });
      }

      return response.data.retorno;
    } catch (error) {
      return null;
    }
  };

  return (
    <div className="container">
      {carregando ? (
        <FaSpinner className="spinner-loader" size={50} color="#e15100" />
      ) : (
        <div className="video-usuario-container">
          {videoBlob ? (
            <>
              <h3>Vídeo do lote: {loteNome}</h3>
              <h3 style={{ marginBottom: 15 }}>
                Instrução solicitada: {descricaoInstrucao}
              </h3>
              <video src={videoBlob} height={380} autoPlay controls></video>
            </>
          ) : (
            <h1>Validação por Liveness!</h1>
          )}

          {scoreBiometrico ? (
            <div className="score-biometrico-container">
              <h4>Score Biométrico</h4>
              <table className="table-group" cellSpacing="0" cellPadding="0">
                <thead>
                  <tr>
                    <th>Score Bem</th>
                    <th>Score Reaproveitamento</th>
                    <th>Score Único</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="width33">
                      {scoreBiometrico.scoreBem ?? "-"}
                    </td>
                    <td className="width33">
                      {scoreBiometrico.scoreReaproveitamento ?? "-"}
                    </td>
                    <td className="width33">
                      {scoreBiometrico.scoreUnico ?? "-"}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table-group table-small"
                cellSpacing="0"
                cellPadding="0"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>CPF</th>
                    <th>Status Processo</th>
                    <th>ID Fornecedor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{scoreBiometrico.id}</td>
                    <td>{scoreBiometrico.nome}</td>
                    <td>{formatarCPF(scoreBiometrico.cpf)}</td>
                    <td>{scoreBiometrico.statusProcessoDescricao}</td>
                    <td>{scoreBiometrico.idFornecedor}</td>
                  </tr>
                </tbody>
              </table>

              {scoreBiometrico.logs && scoreBiometrico.logs.length > 0 ? (
                <>
                  <h4>Score Biométrico - Logs</h4>
                  <table
                    className="table-group table-small"
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <thead>
                      <tr>
                        <th>Log</th>
                        <th className="width33">Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scoreBiometrico.logs.map((log, index) => (
                        <tr key={index}>
                          <td>
                            {log.darDestaque ? (
                              <b>{log.mensagem}</b>
                            ) : (
                              log.mensagem
                            )}
                          </td>
                          <td className="width33">
                            {formatarStringDataHoraSegundos(log.dataCriacao)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : null}

              {scoreBiometrico.calculoScoreBem &&
              scoreBiometrico.calculoScoreBem.length > 0 ? (
                <>
                  <h4>Score Biométrico - Cálculo Score Bem</h4>
                  <table
                    className="table-group table-small"
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <thead>
                      <tr>
                        <th>Descrição</th>
                        <th>Resultado</th>
                        <th>Valor Assinatura</th>
                        <th>Valor Comparação</th>
                        <th>Observação</th>
                        <th>Foto Assinatura</th>
                        <th>Foto Comparação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scoreBiometrico.calculoScoreBem
                        .filter((x) => x.tipoCalculo === 1)
                        .map((item, index) => (
                          <tr key={index}>
                            <td>{item.descricao}</td>
                            <td>{item.positivo ? "Positivo" : "Negativo"}</td>
                            <td>{item.valorAssinatura}</td>
                            <td>{item.valorComparacao}</td>
                            <td>{item.observacao}</td>
                            <td
                              className="td-image"
                              rowSpan={scoreBiometrico.calculoScoreBem.length}
                            >
                              {item.fotoAssinaturaBase64 ? (
                                <img
                                  height={250}
                                  src={`data:image/png;base64, ${item.fotoAssinaturaBase64}`}
                                  alt="Foto assinatura"
                                />
                              ) : (
                                ""
                              )}
                            </td>
                            <td
                              className="td-image"
                              rowSpan={scoreBiometrico.calculoScoreBem.length}
                            >
                              {item.fotoComparacaoBase64 ? (
                                <img
                                  height={250}
                                  src={`data:image/png;base64, ${item.fotoComparacaoBase64}`}
                                  alt="Foto comparação"
                                />
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))}
                      {scoreBiometrico.calculoScoreBem
                        .filter((x) => x.tipoCalculo !== 1)
                        .map((item, index) => (
                          <tr key={index}>
                            <td>{item.descricao}</td>
                            <td>{item.positivo ? "Positivo" : "Negativo"}</td>
                            <td>{item.valorAssinatura}</td>
                            <td>{item.valorComparacao}</td>
                            <td>{item.observacao}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              ) : null}

              {scoreBiometrico.calculoScoreReaproveitamento ? (
                <>
                  <h4>Score Biométrico - Cálculo Score Reaproveitamento</h4>
                  <table
                    className="table-group table-small"
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <thead>
                      <tr>
                        <th>Campo</th>
                        <th>Valor</th>
                        <th>Foto Assinatura</th>
                        <th>Foto Comparação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ID Processo Reaproveitamento</td>
                        <td>
                          {
                            scoreBiometrico.calculoScoreReaproveitamento
                              .idProcessoReaproveitamento
                          }
                        </td>
                        <td className="td-image" rowSpan={4}>
                          {scoreBiometrico.imagemBase64 ? (
                            <img
                              height={250}
                              src={`data:image/png;base64, ${scoreBiometrico.imagemBase64}`}
                              alt="Foto assinatura"
                            />
                          ) : (
                            scoreBiometrico.imagemUrl
                          )}
                        </td>
                        <td className="td-image" rowSpan={4}>
                          {scoreBiometrico.calculoScoreReaproveitamento
                            .fotoProcessoReaproveitamentoBase64 ? (
                            <img
                              height={250}
                              src={`data:image/png;base64, ${scoreBiometrico.calculoScoreReaproveitamento.fotoProcessoReaproveitamentoBase64}`}
                              alt="Foto comparação"
                            />
                          ) : (
                            scoreBiometrico.calculoScoreReaproveitamento
                              .fotoProcessoReaproveitamento
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Facemath</td>
                        <td>
                          {scoreBiometrico.calculoScoreReaproveitamento.facemath
                            ? "Positivo"
                            : "Negativo"}
                        </td>
                      </tr>
                      <tr>
                        <td>Confiança</td>
                        <td>
                          {
                            scoreBiometrico.calculoScoreReaproveitamento
                              .confianca
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Observação</td>
                        <td>
                          {
                            scoreBiometrico.calculoScoreReaproveitamento
                              .observacao
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : null}
            </div>
          ) : (
            <h3 style={{ marginTop: 35 }}>
              Proposta fora dos parâmetros de geração de score biométrico!
            </h3>
          )}
        </div>
      )}
    </div>
  );
}
